import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const charter = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const image = "https://progressivepoliticsni.com/images/charter.png"

  return (
    <Layout location={location} title={siteTitle}>
      <Seo location={location} title="Charter" image={image} />
      <div className="page-charter-wrap">
        <h1>Charter</h1>
          <div className="charter-wrap frame-images">
            <a className="charter-hover" href="/PPNI-CHARTER.pdf">
              <img src="/images/charter-sm.png"></img>
              <a className="charter-button" href="/PPNI-CHARTER.pdf">View</a>
            </a>
          </div>
      </div>
    </Layout>
  )
}

export default charter

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
